@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap");
@layer base {
  /* light theme */
  :root {
    --color-text-base: #333333;
    --color-text-muted: #4b4a4a;
    --color-text-inverted: #ffffff;
    --color-text-muteverted: #dbd4d4;
    --color-fill: #ffffff;
    --color-image: #757373;
    --color-primary: #0f1629;
    --color-nav: #ffffff;
    --color-accent: #39bdd8;
  }

  #light .main {
    background-color: yellow;
  }

  #dark,
  main {
    background-color: #333;
  }

  .theme-light {
    --color-text-base: #333333;
    --color-text-muted: #4b4a4a;
    --color-text-inverted: #ffffff;
    --color-text-muteverted: #dbd4d4;
    --color-fill: red;
  }

  .theme-dark {
    --color-text-base: #ffffff;
    --color-text-muted: #dbd4d4;
    --color-text-inverted: #333333;
    --color-text-muteverted: #4b4a4a;

    /* Gray 900 */
    /* --color-fill: #111827; */
    /* Gray 800 */
    --color-fill: #1f2937;

    --color-primary: #007ebf;
    --color-image: #1f1f1f;
    --color-nav: rgb(51 65 85);
    --color-accent: #b8e0f3;
  }

  /* Dark mode toggle switch */
  body {
    height: 100vh;
    margin: 0;
    transition: all 0.2s linear;
    font-family: "UI" sans-serif;
  }
  body.dark {
    background-color: #292c35;
  }
  .checkbox {
    opacity: 0;
    position: absolute;
  }

  .label {
    width: 32px;
    height: 16px;
    background-color: var(--color-text-base);
    display: flex;
    border-radius: 90px;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    transform: scale(1.5);
  }
  .ball {
    width: 12px;
    height: 12px;
    background-color: var(--color-fill);
    position: absolute;
    top: 2px;
    left: 3px;
    border-radius: 50%;
    transition: transform 0.2s linear;
  }

  /*  target the elemenent after the label*/
  .checkbox:checked + .label .ball {
    transform: translateX(14px);
  }

  .fa-moon {
    color: var(--color-fill);
    font-size: 10px;
  }

  .fa-sun {
    color: #fbbf24;
    font-size: 10px;
  }
}

@layer utilities {
  button,
  input {
    border-radius: 2px;
    outline: none;
  }
  .background--active {
    background-color: yellowgreen;
  }

  button {
    background-color: var(--color-primary);
    text-transform: uppercase;
    font-size: 15px;
    cursor: pointer;
    color: #fff;
    font-weight: 300;
    padding: 10px;
  }

  button:hover {
    color: var(--color-accent);
  }

  input {
    padding: 10px;
    width: 10rem;
    background-color: #dad0d0;
    color: var(--color-text-base);
    transition: all 0.2s ease-in-out;
  }

  #footer-newsletter {
    margin-top: 30px;
  }

  input::placeholder {
    color: var(--color-image);
  }

  input:hover {
    background-color: #dbdbd8;
  }

  .parrilex {
    background-image: url("img/stacked3.svg");
  }

  .see-live {
    background-image: url("img/see-live.png");
  }

  .hero {
    background-image: url("img/latest-single.jpg");
  }

  .bio {
    background-image: url("img/stacked3.svg");
  }

  .video {
    background-image: url("img/stacked7.svg");
  }

  .btn {
    padding: 0.8rem;
    min-width: 300px;
    max-width: 500px;
    background-color: var(--color-fill);
    color: rgb(231, 226, 226);
    font-family: "UI", sans-serif;
    font-size: 24px;
    font-weight: 200;
    cursor: pointer;
    text-transform: uppercase;
  }
  .btn:hover {
    color: var(--color-);
    background-color: var(--color-fill);
  }

  .testy {
    text-shadow: 0px 0px 10px;
  }

  .btn-sm {
    min-width: 7rem;
    padding: 0.7rem;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
    font-weight: 300;
    border-radius: 3px;
  }
  .btn-sm:hover {
    color: var(--color-accent);
  }
  .btn2 {
    text-transform: uppercase;
    padding: 0.5rem 0 0.5rem 0;
    max-width: 800px;
    min-width: 325px;
    color: #fff;
    border: 2px solid #fff;
    text-align: center;
    cursor: pointer;
    z-index: 10;
    transition: all 0.2s ease-in-out;
    font-size: 18px;
    border-radius: 3px;
    font-weight: 300;
    font-family: "UI", sans-serif;
  }

  .hero-btn {
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    min-width: 300px;
    text-align: center;
  }

  .btn2:hover {
    border: 2px solid var(--color-accent);
    color: var(--color-accent);
  }

  .muted {
    color: var(--color-text-muted);
  }

  .heading {
    letter-spacing: 0.1rem;
    font-family: "Dosis", sans-serif;
    font-weight: 300;
  }

  .dosis {
    letter-spacing: 0.1rem;
    font-family: "Dosis", sans-serif;
    font-weight: 300;
  }

  .main-heading {
    font-weight: 300;
    font-family: "Dosis", sans-serif;
    text-transform: uppercase;
  }

  .text-light {
    color: rgb(221, 213, 213);
  }

  .white {
    color: #fff;
  }

  .border-primary {
    border: solid rgb(221, 213, 213);
  }

  .text-dark {
    color: rgb(60, 60, 60);
  }

  @media only screen and (min-width: 630px) {
    .btn2 {
      width: 600px;
    }
  }
}

@layer components {
  .toggle-wrapper {
    width: 50px;
    height: 25px;
    background-color: red;
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  /* Footer */
  .footer-heading {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 1.7rem;
  }

  .footer-text {
    font-size: 1.2rem;
    color: var(--color-text-muted);
  }
}
